function calcular(total, a, b, c) {
    let valorTotalRecebido = parseFloat(total.replace('.','').replace(',','.'));
    let letraA = parseFloat(a.replace('.','').replace(',','.'));
    let letraB = parseFloat(b.replace('.','').replace(',','.'));
    let letraC = parseFloat(c.replace('.','').replace(',','.'));

    let porcentagemTotal = 100.00
    let porcentagemUm = '';
    let porcentagemDois = '';
    let porcentagemTres = '';

    porcentagemUm = (letraA * porcentagemTotal) / valorTotalRecebido;
    porcentagemDois = (letraB * porcentagemTotal) / valorTotalRecebido;
    porcentagemTres = (letraC * porcentagemTotal) / valorTotalRecebido;

    const resultado = {
        valorTotal: valorTotalRecebido,
        primeiroValor: letraA,
        porcentagemUm: porcentagemUm,
        segundoValor: letraB,
        porcentagemDois: porcentagemDois,
        terceiroValor: letraC,
        porcentagemTres: porcentagemTres
                }

    //console.log(resultado);
    //document.getElementById('root').innerHTML = JSON.stringify(resultado);
    document.getElementById("mostrarvalortotal").value =    resultado.valorTotal;

    document.getElementById("mostrarprimeirovalor").value =resultado.primeiroValor;
    document.getElementById("mostrarprimeiroporcentagem").value =resultado.porcentagemUm.toFixed(2);

    document.getElementById("mostrarsegundovalor").value =resultado.segundoValor;
    document.getElementById("mostrarsegundoporcentagem").value =resultado.porcentagemDois.toFixed(2);

    document.getElementById("mostrarterceirovalor").value =resultado.terceiroValor;
    document.getElementById("mostrarterceiroporcentagem").value =resultado.porcentagemTres.toFixed(2);
    
}

const formulario = document.forms.formularioCalculo;

formulario.addEventListener('submit', (e) => {
    e.preventDefault();
    const valorTotal = document.getElementById('valortotal').value;
    const valorUm = document.getElementById('primeirovalor').value;
    const valorDois = document.getElementById('segundovalor').value;
    const valorTres = document.getElementById('terceirovalor').value;

    calcular(valorTotal,valorUm,valorDois,valorTres)
});



